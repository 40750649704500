import styled from "styled-components";
import React from "react";
import Grid from '@material-ui/core/Grid';

import fonts from "../../../config/fonts";
import { IconButton } from "@material-ui/core";
import CustomText from "../../customText/CustomText";
import Styles from "./FooterStyles";
import images from "../../../config/images";
import { Icons, MediaQueries } from "../../../config";


const FooterPage = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    width: "100%",
    paddingBlock: spacing(6),
    display: "flex",
    justifyContent: "space-between",
    [MediaQueries.smDown]: {
      alignItems: "center",
      flexDirection: "column",
    },
  };
});
const ButtonIcon = styled(IconButton)(({ theme }) => {
  const { spacing } = theme;
  return {
    padding: 0,
    borderRadius: 0,
    marginRight: spacing(2),
  };
});

function FooterWebApp() {
  return (
    <FooterPage className="footer-vazio">
      <Grid container justifyContent="center">
          
          
        <Grid item xs={12} sm={12} xl={4} md={4} >
          
          
        </Grid>
        
      </Grid>
    
    </FooterPage>
  );
}

export default FooterWebApp;
