import React from "react";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import Styles from "./HeaderStyles";
import { Colors, MediaQueries, Spacing } from "../../config";
import { makeStyles } from "@material-ui/core/styles";
import { CssBaseline, AppBar, Toolbar, Grid } from "@material-ui/core";
import UserInfo from "./UserInfo/userInfo";
import Menu from "../menu/menu";
import MenuWebApp from "../menu/menuWebApp";
import images from "../../config/images";
import { SessionStorage } from "../../utils";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { paths } from "../../navigation/navigate";
import Hidden from '@material-ui/core/Hidden';


import './header.css';
import './header2.css';
import './webApp.css';

const drawerWidth = 480;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: theme.spacing(17),
  },
  appBar: {
    height: theme.spacing(12),
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: Colors.azulEcom,
    boxShadow: "none",
    // padding: Spacing(2, 20, 0),
    //960
    [MediaQueries.mdDown]: {
      padding: Spacing(2, 1, 0),
    },
  },
  appBarShift: {
    marginLeft: drawerWidth,
    padding: 0,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  customHoverFocus: {
    "&:hover, &.Mui-focusVisible": {
      backgroundColor: "transparent",
    },
  },
  Tabs:{
    color: Colors.branco
  }
}));


  

export default function PersistentDrawerLeft() {
  const classes = useStyles();
  const [open] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    console.log(newValue);
    if(newValue == 0) {
      navigate(paths.home);
    }
    
    if(newValue == 1) {
      navigate(paths.cadastro);
    }
    
    if(newValue == 2) {
      window.open(
        "https://ecomenergia.app4shark.com/"
      );
    }

    if(newValue == 3) {
      navigate(paths.conteudo);
    }

    setValue(newValue);
  };


  return (
    <div className="topo-menu">
      <CssBaseline />
      <Grid container justifyContent="center" spacing={12} >
          <AppBar
              position="fixed"
              // className={clsx(classes.appBar, {
              //   [classes.appBarShift]: open,
              // })}
              className="headerwebapp"
              >
              <Toolbar>
                <Grid
                  container
                  xs={12}
                  direction="row"
                  justifyContent="left"
                  alignItems="center">
                    <Grid item xs={8} sm={8} xl={8} md={8} className="alinha-esquerda-webapp" >
                      
                        <Styles.EcomLogo src={images.logoescrito} onClick={()=>{navigate(paths.homeWebApp)}} />
                      
                    </Grid>
                    <Grid item xs={4} sm={4} xl={4} md={4}  className="alinha-direira-webapp">
                      
                      <MenuWebApp className="menu-header" />
                    </Grid>

                </Grid>
              </Toolbar>
              
            </AppBar>
      </Grid>
    </div>
  );
}
