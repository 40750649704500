import React from "react";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import Styles from "./MenuStyles";
import { Colors, Icons, pagesConfig, Spacing } from "../../config";
import { makeStyles } from "@material-ui/core/styles";
import {
  Drawer,
  List,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Grid,
  withStyles,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import FontStyles from "../../genericComponents/styles/fontStyles";
import { StorageContext } from "../../contexts/StorageContext";
import { customModal } from "../../genericComponents/modals/utils";
import { paths } from "../../navigation/navigate";
import MenuOutlinedIcon from '@material-ui/icons/MenuOutlined';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

const drawerWidth = 480;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    paddingInline: "5%",
    paddingBlock: "1%",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  customHoverFocus: {
    "&:hover, &.Mui-focusVisible": {
      backgroundColor: "transparent",
    },
  },
  customHoverFocusMenu: {
    color: Colors.grayText,
    "&:hover, &.Mui-focusVisible": {
      backgroundColor: "transparent",
      color: Colors.orange,
    },
  },
}));

const NewListItem = withStyles({
  root: {
    backgroundColor: "transparent",
    color: Colors.grayText,
    "& .MuiTypography-body1": {
      color: Colors.grayText,
      ...FontStyles.bold32,
    },
    "&$selected": {
      backgroundColor: "transparent",
      color: Colors.purple,
      "& .MuiListItemIcon-root": {
        filter:
          "invert(85%) sepia(83%) saturate(4488%) hue-rotate(246deg) brightness(90%) contrast(89%)",
      },
      "& .MuiTypography-body1": {
        color: Colors.purple,
      },
      "& .MuiTouchRipple-root": {
        color: "transparent !important",
      },
    },
    "&$selected:hover": {
      backgroundColor: "transparent",
      color: Colors.purple,
      "& .MuiListItemIcon-root": {
        filter:
          "invert(85%) sepia(83%) saturate(4488%) hue-rotate(246deg) brightness(90%) contrast(89%)",
      },
      "& .MuiTypography-body1": {
        color: Colors.purple,
      },
    },
    "&:hover": {
      backgroundColor: "transparent",
      color: Colors.orange,
      "& .MuiListItemIcon-root": {
        filter: "sepia(50%) saturate(5300%) hue-rotate(8deg) brightness(95%)",
      },
      "& .MuiTypography-body1": {
        color: Colors.orange,
      },
    },
  },
  selected: {},
})(ListItem);

export default function PersistentDrawerLeft() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const { logOut } = React.useContext(StorageContext);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const navigate = useNavigate();

  let pathname = window.location.pathname;

  React.useEffect(() => {
    pagesConfig.logged.map((item) => {
      if (
        (pathname.includes("/report/") && item.navigationId === 1) ||
        item.path === pathname
      ) {
        setSelectedIndex(item.navigationId);
      }

      return item;
    });
  }, [pathname]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  function handleLogout() {
    customModal.setInfos(
      "Quer mesmo sair da sua conta?",
      [
        'Você pode sair do sistema deslogando da sua conta clicando no botão de "sair" abaixo.',
      ],
      {
        label: "Sair do sistema",
        onClick: () => {
          customModal.close();
          logOut();
          navigate(paths.login);
        },
      },
      {
        label: "Continuar navegando",
        onClick: () => {
          customModal.close();
        },
      }
    );
  }

  return (
    <div className={classes.root} role="presentation">
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={handleDrawerOpen}
        edge="start"
        className={clsx(
          classes.menuButton,
          open && classes.hide,
          classes.customHoverFocus
        )}>
        {/* <MenuIcon style={{ fill: Colors.purple, width: 32, height: 32 }} /> */}
        <Icons.Menu style={{ width: 35, height: 35 }} />
        {/* <Styles.Text>
          <MenuOutlinedIcon />
        </Styles.Text> */}
      </IconButton>
      <Drawer
        className={classes.drawer}
        ModalProps={{ onBackdropClick: () => setOpen(false) }}
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}>
        <div className={classes.drawerHeader}>
          <IconButton
            onClick={handleDrawerClose}
            className={classes.customHoverFocus}>
            <CloseIcon style={{ width: 32, height: 32 }} />
            <Styles.Text>Fechar</Styles.Text>
          </IconButton>
        </div>
        {pagesConfig.menu.map((item) => (
          <>
            <Styles.Title style={{ paddingLeft: 20 }}>
              {item.groupTitle}
            </Styles.Title>
            {item.pages.map((element) => {
              return (
                <List>
                  <NewListItem
                    button
                    key={element.navigationId}
                    selected={selectedIndex === element.navigationId}
                    onClick={
                      element.working
                        ? element.navigationId !== 3 &&
                          element.navigationId !== 5 &&
                          element.navigationId !== 9
                          ? () =>
                              handleListItemClick(
                                navigate(element.path),
                                element.navigationId
                              )
                          : element.navigationId === 3
                          ? () =>
                              handleListItemClick(
                                window.open(
                                  "https://api.whatsapp.com/send/?phone=5511950485087&text&type=phone_number&app_absent=0"
                                ),
                                element.navigationId
                              )
                          : element.navigationId === 9
                          ? () =>
                              handleListItemClick(
                                window.open(
                                  "https://ecomenergia.app4shark.com/"
                                ),
                                element.navigationId
                              )
                          : element.navigationId === 5
                          ? () =>
                              handleListItemClick(
                                navigate(handleLogout()),
                                element.navigationId
                              )
                          : null
                        : null
                    }>
                    <ListItemIcon>
                      {element.navigationId === 0 ? (
                        <Icons.Home style={{ width: 24 }} />
                      ) : element.navigationId === 1 ? (
                        <Icons.Reports style={{ width: 16 }} />
                      ) : element.navigationId === 2 ? (
                        <Icons.Megaphone style={{ width: 22 }} />
                      ) : element.navigationId === 3 ? (
                        <Icons.TalkToUs style={{ width: 21 }} />
                      ) : element.navigationId === 4 ? (
                        <Icons.Apps style={{ width: 19 }} />
                      ) : element.navigationId === 5 ? (
                        <Icons.Leave style={{ width: 19 }} />
                      ) : element.navigationId === 9 ? (
                        <MonetizationOnIcon style={{ width: 24}} className="icone-comissao" />
                      ) : null}
                    </ListItemIcon>
                    <ListItemText
                      primary={element.title}
                      style={{ marginLeft: -10 }}
                    />
                  </NewListItem>
                </List>
              );
            })}
          </>
        ))}
        <Grid
          container
          xs={10}
          direction="row"
          justifyContent="space-around"
          alignItems="flex-end"
          style={{ marginBottom: Spacing(2) }}>
          <Icons.Linkedin
            style={{ width: 24, cursor: "pointer" }}
            onClick={() => {
              window.open("https://www.linkedin.com/company/ecom-energia/");
            }}
          />
          <Icons.Facebook
            style={{ width: 13, cursor: "pointer" }}
            onClick={() => {
              window.open("https://www.facebook.com/ecomenergia/");
            }}
          />
          <Icons.Insta
            style={{ width: 24, cursor: "pointer" }}
            onClick={() => {
              window.open("https://www.instagram.com/ecomenergia/");
            }}
          />
          <Icons.Youtube
            style={{ width: 34, cursor: "pointer" }}
            onClick={() => {
              window.open(
                "https://www.youtube.com/channel/UC9cs7TzwX8vq4G4NcvoOiPA"
              );
            }}
          />
        </Grid>
      </Drawer>
    </div>
  );
}
