import React from "react";
import Styles from "./HomeStyles";
import { api } from "../../services";
import { CustomText, Footer, FooterWebApp } from "../../genericComponents";
import { Colors, Icons, Spacing } from "../../config";
import fonts from "../../config/fonts";
import NoticeBox from "./components/NoticeBox";
import { hooks, SessionStorage } from "../../utils";
import { useNavigate } from "react-router-dom";
import ReportBox from "./components/ReportBox";
import GraphHome from "./components/GraphHome";
import images from "../../config/images";
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from "@material-ui/core/styles";

import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionActions from '@material-ui/core/AccordionActions';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';

import Input from '@material-ui/core/Input';
import FilledInput from '@material-ui/core/FilledInput';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Select from '@material-ui/core/Select';
import '../../config/home.css';

import MenuItem from '@material-ui/core/MenuItem';
import SearchIcon from '@material-ui/icons/Search';
import SearchTwoToneIcon from '@material-ui/icons/SearchTwoTone';

import Icon from '@material-ui/core/Icon';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import Modal from '@material-ui/core/Modal';

import { paths } from "../../navigation/navigate";
import { useTrackingCode } from "react-hubspot-tracking-code-hook";
import './IndicacoesWebApp.css';
import { useLocation } from "react-router-dom";
import { CircularProgress} from "@material-ui/core";

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  cabecaAcordeao: {
    backgroundColor: Colors.azulEcom,
    color: Colors.white
  },
  tituloAcordeao: {
    color: Colors.white
  },
  corpoAcordeao: {
    marginTop: Spacing(3)
  },
  labelFiltro: {
    color: Colors.black
  },
  MenuItem: {
    '&:hover:not($disabled):not($focused):not($error):before': {
      color: Colors.white
    },
    '&$selected': {
      color: Colors.white
    }
  },
  paper: {
    position: 'absolute',
    width: '40rem',
    maxWidth: '100%',
    maxHeight: '95%',
    overflow: 'auto',
  },
  paper2: {
    width: '94%',
    maxWidth: '100%',
    overflow: 'auto',
    padding: theme.spacing(2, 4, 3),
    backgroundColor: 'white',
    borderRadius: '23px',
    maxHeight: '100%',
    marginLeft: '23px',
    textAlign: 'center',
    fontSize: '18px'

  }
}));

const status = [
  "Indicação Enviada",
  "Cadastro Realizado",
  "Indicação Reprovada"
];

const statusnegocio = [
  "N/A",
  "Em negociação",
  "Negócio fechado",
];

const names = [
  "São Paulo"
];

const UF = [
  {"nome": "Acre", "sigla": "AC"},
  {"nome": "Alagoas", "sigla": "AL"},
  {"nome": "Amapá", "sigla": "AP"},
  {"nome": "Amazonas", "sigla": "AM"},
  {"nome": "Bahia", "sigla": "BA"},
  {"nome": "Ceará", "sigla": "CE"},
  {"nome": "Distrito Federal", "sigla": "DF"},
  {"nome": "Espírito Santo", "sigla": "ES"},
  {"nome": "Goiás", "sigla": "GO"},
  {"nome": "Maranhão", "sigla": "MA"},
  {"nome": "Mato Grosso", "sigla": "MT"},
  {"nome": "Mato Grosso do Sul", "sigla": "MS"},
  {"nome": "Minas Gerais", "sigla": "MG"},
  {"nome": "Pará", "sigla": "PA"},
  {"nome": "Paraíba", "sigla": "PB"},
  {"nome": "Paraná", "sigla": "PR"},
  {"nome": "Pernambuco", "sigla": "PE"},
  {"nome": "Piauí", "sigla": "PI"},
  {"nome": "Rio de Janeiro", "sigla": "RJ"},
  {"nome": "Rio Grande do Norte", "sigla": "RN"},
  {"nome": "Rio Grande do Sul", "sigla": "RS"},
  {"nome": "Rondônia", "sigla": "RO"},
  {"nome": "Roraima", "sigla": "RR"},
  {"nome": "Santa Catarina", "sigla": "SC"},
  {"nome": "São Paulo", "sigla": "SP"},
  {"nome": "Sergipe", "sigla": "SE"},
  {"nome": "Tocantins", "sigla": "TO"}
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};



export default function IndicacoesWebApp() {
  const classes = useStyles();
  const { loading, call } = hooks.useRequest();
  const navigate = useNavigate();
  const [data, setData] = React.useState(null);
  const [bulletins, setBulletins] = React.useState(null);
  const [listReports, setReports] = React.useState(null);
  const [open, setOpen] = React.useState(null);
  const [openAtention, setOpenAtention] = React.useState(false);
  const [carregandopagina, setcarregandopagina] = React.useState(false);
  const [mounted, setMounted] = React.useState(false);
  var viewport_width = document.documentElement.clientWidth;
  const userPerfil = SessionStorage.getItem("userPerfil");
  
  const userEmail = SessionStorage.getItem("email");
  // const { setPathPageView, setIdentity } = useTrackingCode();
  // setIdentity(userEmail);
  // setPathPageView("/dashboard");

  const [modalStyle] = React.useState(getModalStyle);

  const handleNotice = (id) => {
    if (id === open) {
      setOpen(null);
    } else {
      setOpen(id);
    }
  };
  const handleCloseAtention = () => {
    setOpenAtention(false);
  };

  const handleChange = (event) => {
    setPersonName(event.target.value);
  };
  const handleChangeUF = (event) => {
    setEstado(event.target.value);
  };
  const handleChangeStatusNegocio = (event) => {
    setStatusNegocio(event.target.value);
  };
  const handleChangeStatusIndicacao = (event) => {
    setStatusIndicacao(event.target.value);
  };
  const handleCliente = (event) => {
    setCliente(event.target.value);
  };
  const handleRazaoSocial = (event) => {
    setRazaoSocial(event.target.value);
  };
  const handleResponsavel = (event) => {
    setResponsavel(event.target.value);
  };
  const handleMunicipio = (event) => {
    setMunicipio(event.target.value);
  };
  const handleCodigo_uc = (event) => {
    setCodigo_uc(event.target.value);
  };
  

  const [personName, setPersonName] = React.useState([]);
  const [estado, setEstado] = React.useState([]);
  const [status_indicacao, setStatusIndicacao] = React.useState([]);
  const [status_negocio, setStatusNegocio] = React.useState([]);
  const [cliente, setCliente] = React.useState([]);
  const [razao_social, setRazaoSocial] = React.useState([]);
  const [responsavel, setResponsavel] = React.useState([]);
  const [municipio, setMunicipio] = React.useState([]);
  const [codigo_uc, setCodigo_uc] = React.useState([]);

  const [vlrEmpresasCadastradas, setvlrEmpresasCadastradas] = React.useState(0);
  const [vlrIndicacoes, setvlrIndicacoes] = React.useState(0);
  const [vlrIndicacoesEmAndamento, setvlrIndicacoesEmAndamento] = React.useState(0);
  const [vlrClientesFechados, setvlrClientesFechados] = React.useState(0);
  const [vlrNegocioPerdido, setvlrNegocioPerdido] = React.useState(0);

  const [qtdEmpresasCadastradas, setqtdEmpresasCadastradas] = React.useState(0);
  const [qtdIndicacoes, setqtdIndicacoes] = React.useState([]);
  const [qtdIndicacoesValidadas, setqtdIndicacoesValidadas] = React.useState([]);
  const [qtdIndicacoesEmAndamento, setqtdIndicacoesEmAndamento] = React.useState([]);
  const [qtdClientesFechados, setqtdClientesFechados] = React.useState([]);
  const [qtdNegocioPerdido, setqtdNegocioPerdido] = React.useState([]);
  const [rows, setrows] = React.useState([]);
  const [abreAcordeao, setabreAcordeao] = React.useState([]);
  const [dadosParceiro, setdadosParceiro] = React.useState([]);
  

  const handleUC = (event) => {
    setUC(event.target.value);
  };
  const [uc, setUC] = React.useState([]);
  
  const handleCNPJ = (event) => {
    setCNPJ(event.target.value);
  };
  const [cnpj, setCNPJ] = React.useState([]);
  
  const handleDataIndicacao = (event) => {
    setDataIndicacao(event.target.value);
  };
  const [dataIndicacao, setDataIndicacao] = React.useState([]);
  const user_id = SessionStorage.getItem("user_id");
  const avisoAssinatura = SessionStorage.getItem("avisoAssinatura");
  const location = useLocation();

  const mount = React.useCallback(async () => {
    setcarregandopagina(true);
    if(typeof location.state.fantasia != 'undefined') {
      console.log(typeof location.state.fantasia);
      var busca = {"cliente":location.state.fantasia,"razao_social":(razao_social.length > 0 ? razao_social : ''),"responsavel":(responsavel.length > 0 ? responsavel : ''),"municipio":(municipio.length > 0 ? municipio : ''),"estado":(estado.length > 0 ? estado : ''),"codigo_uc":(codigo_uc.length > 0 ? codigo_uc : ''),"cnpj":(cnpj.length > 0 ? cnpj : ''),"status_indicacao":(status_indicacao.length > 0 ? status_indicacao : ''),"status_negocio":(status_negocio.length > 0 ? status_negocio : '')}
      console.log(busca);
      call(null, api.parceirosIndicacoes(user_id, busca), (response) => {
        if(response.ok) {
          console.log('=======================BUSCA===================');
          console.log(response.data);
          setrows(response.data);
          setcarregandopagina(false);
        }
      });


    }
    if(typeof location.state.status_indicacao != 'undefined') {
      console.log(location.state.status_indicacao);
      var busca = {"status_indicacao":location?.state?.status_indicacao}
        console.log(busca);
        call(null, api.parceirosIndicacoes(user_id, busca), (response) => {
          setcarregandopagina(false);
          if(response.ok) {
            console.log('=======================BUSCA===================');
            console.log(response.data);
            setrows(response.data);
            setcarregandopagina(false);
          }
        });
    }
    if(typeof location.state.status_negocio != 'undefined') {
      console.log(location.state.status_negocio);
      var busca = {"status_negocio":location?.state?.status_negocio}
        console.log(busca);
        setcarregandopagina(true);
        call(null, api.parceirosIndicacoes(user_id, busca), (response) => {
          if(response.ok) {
            console.log('=======================BUSCA===================');
            console.log(response.data);
            setrows(response.data);
            setcarregandopagina(false);
          }
        });
    }
    // if
    // if(typeof location.state.fantasia != undefined) {
    //   console.log('STATE');
    //   console.log(location.state.fantasia);
    //   filtrarParceiros(location.state.fantasia);
    // } 
    // else {
    //   console.log(location?.state?.status_indicacao);

    //   if(location?.state?.status_indicacao.length > 0 || typeof location.state.status_indicacao != undefined) {
    //     var busca = {"status_indicacao":location?.state?.status_indicacao}
    //     console.log(busca);
    //     call(null, api.parceirosIndicacoes(user_id, busca), (response) => {
    //       if(response.ok) {
    //         console.log('=======================BUSCA===================');
    //         console.log(response.data);
    //         setrows(response.data);
    //         setcarregandopagina(false);
    //       }
    //     });
    //   }
    // }

    setabreAcordeao(false);
    
    call(null, api.getIndicacoes(user_id), (response2) => {
      setcarregandopagina(true);
      if(response2.ok) {
        setcarregandopagina(false);
        if(response2.data.qtd){
          setqtdIndicacoes(response2.data.qtd);
        } else {
          setqtdIndicacoes('0');
        }
      }
    });
    
    
    
    call(null, api.dadosDoParceiro(user_id), (response5) => {
      setcarregandopagina(true);
      if(response5.ok) {
        setcarregandopagina(false);
        setdadosParceiro(response5.data);
        console.log('dados parceiro');
        console.log(user_id);
        console.log(response5.data);
      }
    });
     
  }, [call]);

  React.useEffect(() => {
    
    if (!mounted) {
      mount();
      setMounted(true);
    }
  }, [mount, mounted]);

  
  const columns = [
    {id: 'cliente', label: 'Cliente', align: 'left', },
    {id: 'responsavel',label: 'Contato',align: 'left'},
    {id: 'cnpj',label: 'CNPJ Unidade',align: 'center'},
  ];
  
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  
  const filtrarParceiros = (fantasia) => {
    setcarregandopagina(true);
   
    var busca = {"cliente":(fantasia.length > 0 ? fantasia : ''),"razao_social":(razao_social.length > 0 ? razao_social : ''),"responsavel":(responsavel.length > 0 ? responsavel : ''),"municipio":(municipio.length > 0 ? municipio : ''),"estado":(estado.length > 0 ? estado : ''),"codigo_uc":(codigo_uc.length > 0 ? codigo_uc : ''),"cnpj":(cnpj.length > 0 ? cnpj : ''),"status_indicacao":(status_indicacao.length > 0 ? status_indicacao : ''),"status_negocio":(status_negocio.length > 0 ? status_negocio : '')}
    console.log(busca);
    call(null, api.parceirosIndicacoes(user_id, busca), (response) => {
      if(response.ok) {
        console.log('=======================BUSCA===================');
        console.log(response.data);
        setrows(response.data);
        setcarregandopagina(false);
      }
    });
  }
  
  

  
  
  return (
    <>
      <Styles.Container>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12} sm={12} xl={9} md={9}>
              <div className="ola_parceiroecom">
                Parceiro Ecom
              </div>
              <hr className="hr"></hr>
            </Grid>
            
            

            
              <Grid item xs={12} sm={12} xl={9} md={9}>
                
                <div className="ola_busca">
                  Consulta Indicações
                  {
                    (carregandopagina == true) 
                    &&
                    <>
                    <CircularProgress 
                      className="carregando-botao-webapp"
                    />
                    </>
                  }
                </div>
              </Grid>
              <br/>
              <br/>
              <br/>
            
            <Grid item xs={12} sm={12} xl={4} md={4}>
              <FormControl fullWidth variant="outlined">
                <TextField
                  id="razao_social"
                  value={razao_social}
                  onChange={handleRazaoSocial}
                  startAdornment={<InputAdornment position="start"></InputAdornment>}
                  labelWidth={60}
                  variant="outlined"
                  label="Digite o nome fantasia"
                  className="campo-fantasia"
                >
                </TextField>
                <Icons.Busca className="icone-busca" onClick={()=>{filtrarParceiros(razao_social)}}></Icons.Busca>
              </FormControl>
            </Grid>
            
          </Grid>
          <br/>
          <br/>
          <Grid container justifyContent="center" spacing={4}>
            <Grid item xs={12} sm={12} xl={2} md={2}>
              <Paper 
                variant="outlined"
                className="papelfinger"

              >
                <Styles.CorpoPaper>
                  <Styles.DivNumeros>
                    {rows.length}
                  </Styles.DivNumeros>
                  <Styles.Pbox>
                    {
                      (location?.state?.status_indicacao == 'Cadastro Realizado' && (razao_social.length <= 0))
                      &&
                      <>
                        Cadastros Realizados    
                      </>
                    }
                    {
                      (location?.state?.status_indicacao == 'Indicação Enviada' && (razao_social.length <= 0))
                      &&
                      <>
                        Indicações Enviadas    
                      </>
                    }
                    {
                      (location?.state?.status_negocio == 'Negócio fechado' && (razao_social.length <= 0))
                      &&
                      <>
                        Clientes Fechados    
                      </>
                    }
                    {
                      (location?.state?.status_negocio == 'Em negociação' && (razao_social.length <= 0))
                      &&
                      <>
                        Em Negociação
                      </>
                    }
                    {
                      (typeof location.state.fantasia != 'undefined' && razao_social.length <= 0)
                      &&
                      <>
                        Resultados para "{location.state.fantasia}"
                      </>
                    }
                    {
                      (razao_social.length > 0)
                      &&
                      <>
                        Resultados para "{razao_social}"
                      </>
                    }
                    
                  </Styles.Pbox>
                </Styles.CorpoPaper>
              </Paper>
            </Grid>
            <br/>
            <br/>
            <br/>
            
          </Grid>
          
            <Styles.CorpoTabela>
              <TableContainer >
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                          {columns.map((column) => {
                            switch (column.id) {
                              case 'cliente':
                                var valor = row.cliente;
                                break;
                              case 'responsavel':
                                var valor = row.responsavel;
                                break;
                              case 'cnpj':
                                var valor = row.cnpj;
                                break;
                            }
                            
                            return (
                              <TableCell 
                                key={column.id} 
                                align={column.align}
                                onClick={()=>{navigate(paths.stepcadastroIndicacaoParceiroWebApp, {state: { empresa_id: row.empresa_id, unidade_consumidora_id: row.unidade_consumidora_id}})}}
                                >
                                {column.format && typeof value === 'number' ? column.format(valor) : valor}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                labelRowsPerPage = "Itens por página"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Styles.CorpoTabela>
            <Grid item xs={12} sm={12} xl={3} md={3} className="alinha-direita">
              <Button
                variant="contained"
                className="botao-voltar"
                onClick={() => {
                  navigate(paths.homeWebApp);
                }}
              >
                Voltar
              </Button>
            </Grid>
            <Modal
              open={openAtention}
              onClose={handleCloseAtention}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              disableScrollLock={false}
              className="modalatention"
          >
          <>
            <div style={modalStyle} className={classes.paper}>
              <img src={images.iconeatencao} className="iconeatencao"  />
              <div className={classes.paper2}>
                <h3 className="h3atention">Assinaturas pendentes</h3>
                <p>Você tem assinaturas pendentes no acordo de parceria!</p>
                <Button 
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      handleCloseAtention();
                    }}
                    className="fecharatencao"
                >
                  Fechar
                </Button>
              </div>
            </div>
          </>
          </Modal>
        
        {/*  ----            Footer               ------------ */}
      </Styles.Container>
      <FooterWebApp />
    </>
  );
}