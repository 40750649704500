import Login from "./login/login";
import ForgetPassword from "./forgetPassword/forgetPassword";
import UserRegister from "./newAccount/NewAccount";
import Home from "./home/home";
import Settings from "./settings/settings";
import Reports from "./reports/reports";
import ReportReader from "./reportReader/reportReader";
import Bulletins from "./bulletins/bulletins";
import Cadastro from "./cadastro/cadastro";
import CadastroIndicacaoParceiro from "./cadastroIndicacaoParceiro/cadastroIndicacaoParceiro";
import NovoCadastroIndicacaoParceiro from "./cadastroIndicacaoParceiro/novocadastroIndicacaoParceiro";
import StepCadastroIndicacaoParceiro from "./cadastroIndicacaoParceiro/stepcadastroIndicacaoParceiro";
import FluxoCadastroIndicacaoParceiro from "./cadastroIndicacaoParceiro/fluxocadastroIndicacaoParceiro";
import FluxoCadastroIndicacaoParceiro2 from "./cadastroIndicacaoParceiro/fluxocadastroIndicacaoParceiro2";
import LoginSso from "./login/loginsso";
import Conteudo from "./conteudo/conteudo";
import HomeWebApp from "./homeWebApp/homeWebApp";
import IndicacoesWebApp from "./indicacoesWebApp/indicacoesWebApp";
import StepCadastroIndicacaoParceiroWebApp from "./cadastroIndicacaoParceiroWebApp/stepcadastroIndicacaoParceiroWebApp";
import CadastroIndicacaoParceiroWebApp from "./cadastroIndicacaoParceiroWebApp/cadastroIndicacaoParceiroWebApp";


const pages = {
  Login,
  ForgetPassword,
  Home,
  UserRegister,
  Settings,
  Reports,
  ReportReader,
  Bulletins,
  Cadastro,
  CadastroIndicacaoParceiro,
  NovoCadastroIndicacaoParceiro,
  StepCadastroIndicacaoParceiro,
  FluxoCadastroIndicacaoParceiro,
  FluxoCadastroIndicacaoParceiro2,
  LoginSso,
  Conteudo,
  HomeWebApp,
  IndicacoesWebApp,
  StepCadastroIndicacaoParceiroWebApp,
  CadastroIndicacaoParceiroWebApp
};
export default pages;
